import React,{useEffect,useState,useRef} from 'react'
import loading from '../../logo.jpg'
import "./explore.css"
import axios from 'axios';
import {MapContainer,TileLayer,Popup,ScaleControl,useMap,useMapEvent,Marker,LayerGroup,LayersControl} from 'react-leaflet';
import "leaflet/dist/leaflet.css"
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import "leaflet-routing-machine";
import L from 'leaflet';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import { Alert, Button} from "@material-tailwind/react";

const Explore = ({serverURL,utente}) => {
  document.title="Explore | Journey"

  const [alertInfo, setAlertInfo] = useState()
  function AlertOk({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        }
        open={open}
        className="alertOk"
        action={
          <Button
            variant="text"
            color="white"
            size="sm"
            className="!absolute top-3 right-3"
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
          >
            Close
          </Button>
        }
      >{text}</Alert>
    );
  }
  function AlertError({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path
              fillRule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        }
        open={open}
        className="alertError"
        action={
          <Button
            variant="text"
            color="white"
            size="sm"
            className="!absolute top-3 right-3"
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
          >Close</Button>
        }
      >{text}</Alert>
    );
  }

  const [citta,setCitta]=useState()
  const [view,setView]=useState()
  const [viewFilter,setViewFilter]=useState(false)
  const [filter,setFilter]=useState(["attractions"])
  const [filterText,setFilterText]=useState("")
  const [result,setResult]=useState()
  const [info,setInfo]=useState()
  const [caricamento,setCaricamento]=useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(navigator.language.split('-')[0])
  const locateControlRef=useRef(null);
  async function findAttractionBounds(latNe,lonNe,latSw,lonSw){
    setCaricamento(true)
    await axios.put(serverURL+"wikiBound",{
      latNe:latNe,lonNe:lonNe,latSw:latSw,lonSw:lonSw,
      filtro:filter
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        if(e.data.length>0){
          setResult(e.data)
          if(e.data.length===0){
            setAlertInfo({type:'error',text:"No results in this area"})
          }
        }else{
          setAlertInfo({type:'error',text:"No results in this area"})
          setResult()
        }
      }else{
        setAlertInfo({type:'error',text:"Error: "+e.data})
      }
    }).catch(err=>setAlertInfo({type:'error',text:"Error: "+err}))
  }
  //ottieni i testi per ogni attrazione
  const wikiText=async(nome,wikipedia,wikidata,lat,lon,id,img)=>{
    if(lat&&lon){
      if(!localStorage.getItem("cronology")){
        localStorage.setItem("cronology",JSON.stringify([{nome:nome,lat:lat,lon:lon,img:img,data:new Date()}]))
      }else{
        const cronology=JSON.parse(localStorage.getItem("cronology"))
        if(!cronology.find(e=>e.nome===nome)){
          if(cronology.length>=10)cronology.shift()
          cronology.push({nome:nome,lat:lat,lon:lon,img:img,data:new Date()})
          localStorage.setItem("cronology",JSON.stringify(cronology))
        }
      }
    }
    setInfo()
    setCaricamento(true)
    axios.put(serverURL+"wikiText",{
      nome:nome,
      wikipedia:wikipedia,
      wikidata:wikidata,
      lat:lat,
      lon:lon,
      img:img,
      lingua:document.getElementById("language").value,
      id:utente?utente._id:null,
      data:new Date()
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
      setCaricamento(false)
      if(e.status===200){
        axios.put(serverURL+"getSubmitToIdWiki",{idWiki:id},{headers:{'Content-Type':'multipart/form-data'}}).then(j=>{
          if(j.status===200){
            setInfo({id:id,nome:nome,info:e.data,submitted:j.data});
          }else{
            setInfo({id:id,nome:nome,info:e.data});
          }
        })
        document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        document.getElementById("resizableDiv-info").style.opacity="1"
        if(document.getElementById("resizableDiv-info").style.transform="translateY(95%)"){
          document.getElementById("resizableDiv-info").style.transform="translateY(0%)"
        }
      }else{
        setAlertInfo({type:'error',text:"Error: "+e.data})
      }
    }).catch(err=>setAlertInfo({type:'error',text:"Error: "+err}))
  }
  //leggi il testo
  async function leggiTesto(testo,titolo,x2,y2){
    if(utente){
      setCaricamento(true)
      const audio=document.getElementById("audio")
      audio.scrollIntoView({behavior:"smooth"})
      audio.src=null
      audio.load()
      axios.put(serverURL+"wikiAudio",{
        testo:testo,
        titolo:titolo,
        lingua:document.getElementById("language").value,
        id:utente.nome
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          setTimeout(()=>{
            audio.src=(serverURL+e.data)
            audio.type='audio/mpeg'
            audio.load()
          },500)
        }else{
          setAlertInfo({type:'error',text:"Error: "+e.data})
        }
      }).catch(err=>setAlertInfo({type:'error',text:"Error: "+err}))
    }else{
      setAlertInfo({type:'error',text:"Audio Error: To obtain this attraction point audio you must be registered <a href='/login'>login</a>"})
    }
  }
  //vedi il testo
  /*async function vediTesto(testo,titolo,imgs,x2,y2){
    if(utente){
      if(window.confirm("The waiting time is very long, are you sure you want to continue?")){
        setCaricamento(true)
        const audio=document.getElementById("audio")
        audio.scrollIntoView({behavior:"smooth"})
        audio.src=null
        audio.load()
        let distanza
        if(x2&&y2){
          distanza=calcolaDistanza(coords[0],coords[1],x2,y2)
          if(titolo==="In generale"){
            testo="Puoi vederlo alla distanza di "+(distanza).toFixed(1)+". "+testo
          }else if(titolo==="In general"){
            testo="You can see it at the distance of "+(distanza).toFixed(1)+". "+testo
          }
        }
        axios.put(serverURL+"wikiVideo",{
          testo:testo,
          titolo:titolo,
          imgs:imgs,
          lingua:document.getElementById("language").value,
          id:utente.nome
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
          setCaricamento(false)
          if(e.status===200){
            setTimeout(()=>{
              audio.src=(serverURL+e.data)
              audio.type='video/mp4'
              audio.load()
            },500)
          }else{
            alert("Error: "+e.data)
          }
        })
      }
    }else{
      alert("Audio Error: To obtain this attraction point audio you must be registered <a href='/login' target='_blank'>Login</a>.")
    }
  }*/
  async function clickLocateControl(){
    setTimeout(()=>{
      if (locateControlRef){
        try{
          locateControlRef.current.start();
          navigator.geolocation.getCurrentPosition((position) => {setView([position.coords.latitude, position.coords.longitude])})
        }catch(err){
          console.error(err);
        }
        return
      }else{
        clickLocateControl()
      }
    },1000)
  }
  useEffect(()=>{
    if(window.location.search){
      const urlParams = new URLSearchParams(window.location.search);
      setView([urlParams.get('lat'), urlParams.get('lon')]);
    }else{
      setView([41.9028, 12.4964]);
      clickLocateControl()
    } 
  },[])
  //componente per muovere la div nera e la div bianca dei risultati
  useEffect(()=>{
    //line-info
    if(document.getElementById('line-info')&&document.getElementById('resizableDiv-info')){
      const line = document.getElementById('line-info');
      const resizableDiv = document.getElementById('resizableDiv-info');
      let isDragging = false;
      let startY;
      let lastY;
      line.addEventListener("click", () => {
        setInfo()
        resizableDiv.style.transform="translateY(100%)"
        resizableDiv.style.opacity="0"
      });
      line.addEventListener('mousedown', startDragging);
      line.addEventListener('touchstart', startDragging);
      function startDragging(event) {
        isDragging = true;
        startY = event.clientY||event.touches[0].clientY;
        lastY = startY;
      }
      document.addEventListener('mousemove', drag);
      document.addEventListener('touchmove', drag);
      function drag(event) {
        if (isDragging) {
          let clientY = event.clientY || event.touches[0].clientY;
          let deltaY = clientY - lastY;
          if (deltaY < 0) {
            resizableDiv.style.transform="translateY(0%)"
          } else if (deltaY > 0) {
            setInfo()
            resizableDiv.style.transform="translateY(100%)"
            resizableDiv.style.opacity="0"
          }
          lastY = clientY;
        }
      }
      document.addEventListener('mouseup', stopDragging);
      document.addEventListener('touchend', stopDragging);
      function stopDragging() {
        isDragging = false;
      }
    }
  },[document.getElementById('line-info'),document.getElementById('resizableDiv-info')])
  function distanza(p1, p2) {
    const dx = p1[0] - p2[0];
    const dy = p1[1] - p2[1];
    return Math.sqrt(dx * dx + dy * dy);
  }
  function ordinaPuntiVicinoPiuVicino(punti) {
    const puntiOrdinati = [];
    let puntoCorrente = punti[0];
    puntiOrdinati.push(puntoCorrente);
    let nonVisitati = punti.slice(1);
    while (nonVisitati.length > 0) {
      let puntoPiuVicino = nonVisitati[0];
      let distanzaMinima = distanza(puntoCorrente, puntoPiuVicino);
      for (let i = 1; i < nonVisitati.length; i++) {
        const dist = distanza(puntoCorrente, nonVisitati[i]);
        if (dist < distanzaMinima) {
          distanzaMinima = dist;
          puntoPiuVicino = nonVisitati[i];
        }
      }
      puntoCorrente = puntoPiuVicino;
      puntiOrdinati.push(puntoCorrente);
      nonVisitati = nonVisitati.filter(p => p !== puntoPiuVicino);
    }
    return puntiOrdinati;
  }
  const openTextOSummary=(id)=>{
    if(document.getElementById(id).style.height==="140px"){
      document.getElementById(id).style.height="fit-content"
    }else{
      document.getElementById(id).style.height="140px"
    }
  }
  function ChangeView(){
    const map=useMap();
    useEffect(()=>{
      map.setView(view)
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        position: "topleft",
        provider: provider,
        autoClose: true,
        autoComplete: true,
        autoCompleteDelay: 250,
      });
      map.addControl(searchControl)
      const box = document.querySelector('.geosearch ');
      if (box) {           
        box.style.borderRadius = '50%';
        box.style.border= 'none'     
      }
      const buttonSearch = document.querySelector('.geosearch  a');
      if (buttonSearch) {
        buttonSearch.style.backgroundColor = 'white';              
        buttonSearch.style.borderRadius = '50%'; 
        buttonSearch.style.boxShadow= '0 2px 5px #888'
      }
      const form = document.querySelector('.geosearch form');
      if (form) {
        form.style.marginLeft = '0px';
        form.style.boxShadow= 'none'  
        form.style.backgroundColor = 'transparent';    
      }
      const formInput = document.querySelector('.geosearch input');
      if (formInput) {
        formInput.style.borderRadius = '20px';
        formInput.style.boxShadow = '0 2px 5px #888';   
      }
      const formButton = document.querySelector('.geosearch button');
      if (formButton) { 
        formButton.style.borderRadius = '0 20px 20px 0';
      }
      const formResult = document.querySelector('.geosearch .results');
      if (formResult) { 
        formResult.style.borderRadius = '20px';
        formResult.style.boxShadow = '0px 2px 5px #888';
        formResult.style.marginTop = '10px';
        formResult.style.border = 'none';
      }
      return () => {
        map.removeControl(searchControl);
      };
    },[map])
    useMapEvent("moveend",async ()=>{
      try{
        await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${map.getCenter().lat}&lon=${map.getCenter().lng}&format=json`).then(e=>e.data&&!e.data.error&&setCitta(e.data)).catch(err=>console.log("Error: "+err.text));
        setView(map.getCenter())
      }catch(err){
        console.log(err);
      }
    })
  }
  const [locateActive, setLocateActive] = useState(false);
  const LocateControl=()=>{
    const map=useMap();
    useEffect(()=>{
      if(locateControlRef.current){
        return;
      }
      const lc=L.control.locate({
        position:'topleft',
        setView:'once',
        keepCurrentZoomLevel:true,
        strings:{
          title:"I am here",
        },
        locateOptions:{
          enableHighAccuracy:true,
        },
        onLocationError:(err)=>{
          setAlertInfo({type:'error',text:"To improve your experience, please enable GPS from your device settings or allow location access in your browser settings."})
        }
      });
      lc.addTo(map);
      locateControlRef.current = lc;
      const box = document.querySelector('.leaflet-control-locate');
      if (box) {           
        box.style.borderRadius = '50%';
        box.style.border= 'none'
        box.style.boxShadow= '0 2px 5px #888'           
      }
      const buttonLocate = document.querySelector('.leaflet-control-locate a');
      if (buttonLocate) {
        buttonLocate.style.backgroundColor = 'white';              
        buttonLocate.style.borderRadius = '50%';         
      }
      locateControlRef.current=lc;
    },[map]);
    return null;
  };
  //per dare la possibilità agli utenti di scrivere di una particolare attrazione turistica
  /*const [write,setWrite]=useState(false)
  const submit=(text,file,idwiki)=>{
    setCaricamento(true)
    axios.put(serverURL+"submitToIdWiki",{
      nome:utente.nome,
      email:utente.email,
      password:utente.password,
      text:text,
      file:file,
      idWiki:idwiki
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        setCaricamento(false)
        if(e.status===200){
          window.location.reload()
        }else{
          alert("Login Error: "+e.data)
        }
    })
  }*/
  const MarkerCluster = ({ children }) => {
    const map = useMap();
    // Stato per le posizioni evidenziate
    const [highlightedPositions, setHighlightedPositions] = React.useState([]);
    useEffect(() => {
      // Leggi le posizioni evidenziate dal LocalStorage
      let array
      if(localStorage.getItem("cronology")){
        if(utente&&utente.cronology){
          array=JSON.parse(localStorage.getItem("cronology")).concat(utente.cronology)
        }else{
          array=JSON.parse(localStorage.getItem("cronology"))
        }
      }
      const storedPositions = array || [];
      setHighlightedPositions(storedPositions);
    }, []); // Solo all'inizio
    useEffect(() => {
      const markerClusterGroup = L.markerClusterGroup();
      map.addLayer(markerClusterGroup);
      children&&children.forEach((child, n)=>{
        if (child&&child.props.position){
          // Controlla se la posizione è evidenziata
          const isHighlighted = highlightedPositions.some(
            (pos)=>pos.lat===child.props.position[0]&&pos.lon===child.props.position[1]
          );
          let marker;
          if (isHighlighted) {
            const customIcon = L.divIcon({
              className: "custom-marker", // Classe CSS per gestire lo stile
              html: `
                <div title="View" style="position: relative; display: inline-block;">
                  ${child.props.icon.options.html}
                  <span title="Done" style="
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    background-color: #50C878;
                    color: white;
                    font-size: 12px;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                  ">✓</span>
                </div>
              `,
              iconSize: child.props.icon.options.iconSize,
              iconAnchor: child.props.icon.options.iconAnchor,
            });
            marker = L.marker(child.props.position, { icon: customIcon });
          } else {
            // Usa l'icona normale se non evidenziata
            marker = L.marker(child.props.position, { icon: child.props.icon });
          }
          if (child.props.children) {
            console.log(child.props.children.props.children[1].props);
            marker
              .bindPopup(
                "<big>"+child.props.children.props.children[0].props.children + "</big>" +
                "<br/><small id=popup-click-"+n+" style='color:#0096FF;cursor:pointer;text-decoration:underline'>" +
                child.props.children.props.children[1].props.children +
                "</small><br><small><a style='color:#0096FF' href='" +
                child.props.children.props.children[2].props.href +
                "' target='_blank'>" +
                child.props.children.props.children[2].props.children +
                "</a></small>"
              ).addEventListener("click", () => {
                document.getElementById("n" + n).style.display = "block";
                document.getElementById("n" + n).scrollIntoView({ behavior: "smooth" });
              }).on("popupopen",()=>{
                const clickableElement = document.getElementById(`popup-click-${n}`);
                if (clickableElement) {
                  clickableElement.addEventListener("click", () => {
                    const dynamicFunction=eval(child.props.children.props.children[1].props.onClick);
                    dynamicFunction()
                  });
                }
              });
          }
          markerClusterGroup.addLayer(marker);
        }
      });
      return () => {
        map.removeLayer(markerClusterGroup);
      };
    }, [children,highlightedPositions,map]);
    return null;
  };    
  function GetPosition(){
    const map = useMap();
    const handleButtonClick =()=>{
      findAttractionBounds(map.getBounds().getNorthEast().lat,map.getBounds().getNorthEast().lng,map.getBounds().getSouthWest().lat,map.getBounds().getSouthWest().lng)
    };
    return (
      <div className='start-content-map-button'>
        <button onClick={handleButtonClick}>Search Here</button>
      </div>
    );
  }
  function addFilter(filtro){
    if(filter.includes(filtro)){
      setFilter(filter.filter(e=>e!==filtro))
    }else{
      setFilter([...filter,filtro])
    }
  }
  function GetFilter(){
    return(
      <div className='start-content-map-types'>
        <div title='Filter' className='start-content-map-types-filter' onClick={()=>setViewFilter(e=>!e)}>
          <div className='start-content-map-types-filter-counter'>
            <small>{filter.length}</small>
          </div>
          <svg viewBox="0 0 16 16">
            {!viewFilter?<path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"/>:<path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z"/>}
          </svg>
        </div>
        {viewFilter&&<div className='start-content-map-types-scroll'>
          <div style={!filter.includes('art')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='art' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('art')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Art</small>
          </div>
          <div style={!filter.includes('castles')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='castles' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('castles')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Castles</small>
          </div>
          <div style={!filter.includes('archaeological_sites')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='archaeological_sites' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('archaeological_sites')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Dig Sites</small>
          </div>
          <div style={!filter.includes('attractions')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='attractions' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('attractions')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Main Attractions</small>
          </div>
          <div style={!filter.includes('museums')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='museums' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('museums')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Museums</small>
          </div>
          <div style={!filter.includes('religious_places')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='religious_places' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('religious_places')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Religious Places</small>
          </div>
          <div style={!filter.includes('ruins')?{background:"white",color:"var(--black)"}:{background:"var(--black)",color:"white"}} id='ruins' onClick={(e)=>addFilter(e.currentTarget.id)} className='start-content-map-types-scroll-item'>
            <svg viewBox="0 0 16 16">
              {!filter.includes('ruins')?<path fill='var(--black)' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>:
              <path fill='white' d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1z"/>}
            </svg>
            <small>Ruins</small>
          </div>
        </div>}
      </div>
    )
  }
  function GetCity(){
    return(
      <div className='start-content-map-city' title={'Show '+(citta.address.village||citta.address.city||citta.address.town)} onClick={()=>wikiText(citta.address.village||citta.address.city||citta.address.town)}>
        <div className='start-content-map-city-svg'>
          <svg viewBox="0 0 16 16">
            <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
            <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
          </svg>
        </div>
        <div className='start-content-map-city-small'>
          <small>Show {(citta.address.village||citta.address.city||citta.address.town)}</small>
        </div>
      </div>
    )
  }
  function GetLanguage({selectedLanguage, setSelectedLanguage }){
    return (
      <div className='start-content-map-select'>
        <small>Select Content Language:</small>
        <select id="language" value={selectedLanguage} onChange={(event)=>setSelectedLanguage(event.target.value)}>
          <option value="it">Italiano</option>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="fr">Français</option>
          <option value="de">Deutsch</option>
          <option value="nl">Nederlands</option>
          <option value="pt">Português</option>
          <option value="ru">Russkiy</option>
          <option value="zh">Zhōngguó rén (Chinese)</option>
          <option value="ja">Nihongo (Japanese)</option>
        </select>
      </div>
    );
  }
  const [deleteVisible,setDeleteVisible]=useState(false)
  const RouteWithTooltip=({ waypoints })=>{
    const map = useMap(); // Accesso alla mappa corrente tramite React-Leaflet
    const routingControlRef = useRef(null); // Memorizza il controllo di routing
    useEffect(() => {
      try{
        if (!map || !waypoints || waypoints.length < 2) return; // Verifica che ci siano waypoint validi
        // Crea il controllo di routing
        const routingControl = L.Routing.control({
          waypoints: waypoints.map(coords => L.latLng(coords[0], coords[1])),
          lineOptions: {
            styles: [{ color: '#4a80f5', weight: 3 }]
          },
          createMarker: () => null
        });
        routingControl.addTo(map);
        routingControlRef.current = routingControl; // Memorizza il controllo
        // Funzione di cleanup per rimuovere il controllo
        return () => {
          if (routingControlRef.current) {
            routingControlRef.current.getPlan().setWaypoints([]); // Pulisce i waypoint
            map.removeControl(routingControlRef.current); // Rimuove il controllo dalla mappa
            routingControlRef.current = null; // Resetta il riferimento
          }
        };
      }catch(err){
        console.log(err)
        return
      }
    },[waypoints]);
  };
  const [visibleCount, setVisibleCount] = useState(10)
  function ViewVote(id){
    const [vote,setVote]=useState([])
    useEffect(()=>{
      const getVote=async()=>{
        const votes= await axios.get(serverURL+"getVote/"+id)
        setVote(votes)
      }
      getVote().catch(err=>setAlertInfo({type:'error',text:'Error loading votes'}))
    },[])
    if(vote.status===200){
      if(vote.data.length>0){
        const sum=vote.data.map(e=>e.vote).reduce((a,b)=>a+b)
        return <big>{(sum/vote.data.length).toFixed(1)}</big>
      }else{
        return <big>{(Math.random() * (5 - 3.5) + 3.5).toFixed(1)}</big>
      }
    }else{
      return <big>{(Math.random() * (5 - 3.5) + 3.5).toFixed(1)}</big>
    }
  }
  return (
    <div className="start" id='start'>
      {alertInfo&&(alertInfo.type==='ok'?<AlertOk text={alertInfo.text}/>:<AlertError text={alertInfo.text}/>)}
      {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
      <div className='start-content'>
        <div className='start-content-map' style={{height: window.innerHeight-55}}>
          {view?<MapContainer worldCopyJump={false} center={view} zoom={16.5} minZoom={1} zoomControl={false} maxBounds={[[-90, -180],[90, 180],]} maxBoundsViscosity={1.0}>
            <LocateControl activate={locateActive}/>
            <LayersControl position='bottomleft'>
              <LayersControl.BaseLayer name="Open Street Map">
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://maps.geoapify.com/v1/tile/positron/{z}/{x}/{y}.png?apiKey=d85dfbb4d1db4fcc871b071360faf033"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer checked name="Google Map">
                <TileLayer
                  attribution="Google Maps <svg class='svg-info' viewBox='0 0 16 16'><path d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2'/></svg>"
                  url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    attribution="Google Maps Satellite"
                    url="https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                </LayerGroup>
              </LayersControl.BaseLayer>
            </LayersControl>
            <ChangeView center={view}/>
            <GetLanguage selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            <GetFilter/>
            <GetPosition/>
            {citta&&(citta.address.village||citta.address.city||citta.address.town)&&<GetCity/>}
            {result&&result.filter(item => item.tags?.tourism === "attraction").map(item =>item.lat ? [item.lat, item.lon] : [(item.bounds.maxlat + item.bounds.minlat) / 2, (item.bounds.maxlon + item.bounds.minlon) / 2]).length>=2&&<div className='start-content-map-hidePanel'>
              <div className='start-content-map-hidePanel-button' onClick={()=>setDeleteVisible(!deleteVisible)} title={deleteVisible?"Hide Route":"Show Route"}>
                {deleteVisible?<svg fill='var(--red)' viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>:<svg fill="var(--green)" viewBox="0 0 16 16">
                  <path d="M5 8.5A2.5 2.5 0 0 1 7.5 6H9V4.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L9.41 8.658A.25.25 0 0 1 9 8.466V7H7.5A1.5 1.5 0 0 0 6 8.5V11H5z"/>
                  <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134Z"/>
                </svg>}
              </div>
              {deleteVisible&&<RouteWithTooltip
                waypoints={ordinaPuntiVicinoPiuVicino(result.filter(item => item.tags?.tourism === "attraction").map(item =>item.lat ? [item.lat, item.lon] : [(item.bounds.maxlat + item.bounds.minlat) / 2, (item.bounds.maxlon + item.bounds.minlon) / 2]))}
              />}
            </div>}
            <MarkerCluster>
              {result&&result.filter(item => {
                if (filter.length === 0) return true
                const tags = item.tags;
                return filter.some(f => 
                  f?.includes(tags.tourism) || 
                  f?.includes(tags.historic) || 
                  f?.includes(tags.amenity)
                );
              }).map((item,n)=>{
                if((item.lat&&item.lon)||item.bounds){
                  let x2=0
                  let y2=0
                  if(item.lat&&item.lon){
                    x2=item.lat
                    y2=item.lon
                  }else if(item.bounds){
                    x2=(item.bounds.maxlat+item.bounds.minlat)/2
                    y2=(item.bounds.maxlon+item.bounds.minlon)/2
                  }
                  const coordinates=item.lat?[item.lat,item.lon]:[(item.bounds.maxlat+item.bounds.minlat)/2,(item.bounds.maxlon+item.bounds.minlon)/2]
                  if(item.imageUrl){
                    return(
                      <Marker
                        key={n}
                        position={coordinates}
                        icon={L.divIcon({
                          className: 'custom-icon',
                          html: `<div style="display: flex; flex-direction: column; align-items: center;">
                            <img src="${item.imageUrl}" alt="${item.tags["name:en"]||item.tags["name"]}" style="width: 50px; height: 50px; border-radius: 50%;" />
                            <span style="background-color: white; padding: 2px 5px; border-radius: 5px; margin-top: 2px; font-size: 8px; text-align: center; box-shadow: 0 2px 5px #888">${item.tags["name:en"]||item.tags["name"]}</span>
                          </div>`,
                          iconSize: [50, 50],
                          iconAnchor: [25, 25]
                        })}
                      >
                        <Popup>
                          <div style={{cursor:"pointer"}}>{item.tags["name:en"]||item.tags["name"]}</div>
                          <div style={{cursor:"pointer",color:"var(--black)"}} onClick={()=>wikiText(item.tags.name,item.tags.wikipedia,item.tags.wikidata,x2,y2,item.id,item.imageUrl?item.imageUrl:loading)}>Description</div>
                          <a style={{cursor:"pointer",color:"var(--black)"}} href={"https://www.google.com/maps/search/?api=1&query="+coordinates[0]+','+coordinates[1]}>Directions</a>
                        </Popup>
                      </Marker>
                    )
                  }else{
                    return(
                      <Marker
                        key={n}
                        position={coordinates}
                        icon={L.icon({
                            iconUrl:"https://cdn-icons-png.flaticon.com/512/25/25613.png",
                            iconSize: [30, 30],
                            iconAnchor: [25, 25]
                        })}
                      >
                        <Popup>
                          <div style={{cursor:"pointer"}} onClick={()=>document.getElementById("n"+n).scrollIntoView({behavior:"smooth"})}>{item.tags["name:en"]||item.tags["name"]}</div>
                          <div style={{cursor:"pointer",color:"var(--black)"}} onClick={()=>wikiText(item.tags.name,item.tags.wikipedia,item.tags.wikidata,x2,y2,item.id,item.imageUrl?item.imageUrl:loading)}>Description</div>
                          <a style={{cursor:"pointer",color:"var(--black)"}} href={"https://www.google.com/maps/search/?api=1&query="+coordinates[0]+','+coordinates[1]}>Directions</a>
                        </Popup>
                      </Marker>
                    )
                  }
                }
              })}
            </MarkerCluster>
            <ScaleControl position="bottomright"/>
          </MapContainer>:<div style={{textAlign:"center",marginTop:"35%"}}>
            Loading...
          </div>}
        </div>
        <div className='start-content-results'>
          {result&&<div className='start-content-results-input'>
            <input type="text" placeholder='Find...' onChange={(e)=>setFilterText(e.target.value)}/>
          </div>}
          <div className='start-content-results-scroll'>
            {result?result.filter(item => {
              if (filter.length === 0) return true
              const tags = item.tags;
              return filter.some(f => 
                f?.includes(tags.tourism) || 
                f?.includes(tags.historic) || 
                f?.includes(tags.amenity)
              );
            }).filter(item=>(item.tags["name:en"]||item.tags["name"]).toLowerCase()?.includes(filterText.toLowerCase()))
            .map((item,n)=>{
              let x2=0
              let y2=0
              if(item.lat&&item.lon){
                x2=item.lat
                y2=item.lon
              }else if(item.bounds){
                x2=(item.bounds.maxlat+item.bounds.minlat)/2
                y2=(item.bounds.maxlon+item.bounds.minlon)/2
              }
              let array
              if(localStorage.getItem("cronology")){
                if(utente&&utente.cronology){
                  array=JSON.parse(localStorage.getItem("cronology")).concat(utente.cronology)
                }else{
                  array=JSON.parse(localStorage.getItem("cronology"))
                }
              }
              const storedPositions = array || [];
              return(
                <div id={'n'+n} key={n} className='start-content-results-item' style={n>=10?{display:"none"}:null}>
                  {storedPositions.find(e=>e.lat===x2&&e.lon===y2)&&<div title="View" style={{position:"relative",marginLeft:"270px"}}>
                    <span title="Done" style={{position: "absolute",top: "0px",right: "-5px",backgroundColor: "#50C878",color: "white",fontSize: "15px",borderRadius: "50%",width: "20px",height: "20px",display: "flex",alignItems: "center",justifyContent: "center",boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)"}}>✓</span>
                  </div>}
                  <div style={{position:"relative"}}>
                    <svg style={{position: "absolute",top: "8px",left: "8px",width: "20px",height: "20px",display: "flex",alignItems: "center",justifyContent: "center",fill:"var(--secondo)"}} viewBox="0 0 16 16">
                      <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>
                      <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5"/>
                    </svg>
                  </div>
                  <div className='start-content-results-item-img' onClick={()=>wikiText(item.tags.name,item.tags.wikipedia,item.tags.wikidata,x2,y2,item.id,item.imageUrl?item.imageUrl:loading)} style={item.imageUrl?{backgroundImage:"url("+item.imageUrl+")"}:{backgroundImage:"url("+loading+")",backgroundSize:"contain"}}></div>
                  <div className='start-content-results-item-info'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"/>
                    </svg>
                    <div className='start-content-results-item-text' onClick={()=>setView([x2,y2])}>
                      <div className='start-content-results-item-title'>{item.tags["name:en"]||item.tags["name"]}</div>
                      <small>{item.tags.tourism||item.tags.historic||item.tags.amenity}</small>
                    </div>
                  </div>
                </div>
              )
            }):!result&&""}
            {result&&visibleCount<result.filter(item => {
              if (filter.length === 0) return true
              const tags = item.tags;
              return filter.some(f => 
                f?.includes(tags.tourism) || 
                f?.includes(tags.historic) || 
                f?.includes(tags.amenity)
              );
            }).length&&<div className='start-content-results-showMore' onClick={()=>{
              setVisibleCount(visibleCount+10)
              for(let i=visibleCount;i<visibleCount+10;i++){
                if(document.getElementById('n'+i))document.getElementById('n'+i).style.display="block"
              }
            }}>
              <p>Show More</p>
              <svg viewBox="0 0 16 16">
                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
              </svg>
            </div>}
          </div>
        </div>
        <div className='start-content-info' id='resizableDiv-info'>
          <div className='line' id='line-info'>
            <div className='line-line'><br/></div>
          </div>
          {info&&citta&&(citta.address.village||citta.address.city||citta.address.town)===info.nome?<div className='start-content-info-content' id='resizableDiv-info-content' style={{backgroundImage:"url("+loading+")",backgroundPosition:"center",backgroundSize:"contain"}}>
            <div className='start-content-info-content-content'>
              <div className='start-content-info-content-content-title'>
                <h2>{info.nome}</h2>
                <div className='start-content-info-content-content-title-rating'>
                  <svg viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                  </svg>
                  <ViewVote id={citta.osm_id}/>
                  <select onChange={(e)=>utente?axios.put(serverURL+"setVote",{email:utente.email,password:utente.password,idUtente:utente._id,idWiki:citta.osm_id,vote:e.target.value},{headers:{'Content-Type':'multipart/form-data'}}):setAlertInfo({type:'error',text:'You must be logged in to vote'})}>
                    <option value="">Vote</option>
                    {[1,1.5,2,2.5,3,3.5,4,4.5,5].map((i,n)=>{
                      return(
                        <option key={n} value={i}>{i}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              {info.info[0]&&info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-content-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <br />
              <div>
                <audio controls id='audio'></audio>
              </div>
              <br />
              <big>Summary</big>
              <div className='start-content-info-content-summary'>
                <div>{info.info&&info.info.map(i=>i.riassunto+" ").join(' ')}</div>
              </div>
              <br />
              <big>Text</big>
              <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
                <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
              </div>
              <br />
              <big>Audio</big>
              <div className='start-content-info-content-list'>
                <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                  <div className='start-content-info-content-list-item-svg'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                    </svg>
                  </div>
                  <div>Summary</div>
                </div>
                {info&&info.info.map((i,n)=>{
                  return(
                    <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                      <div className='start-content-info-content-list-item-svg'>
                        <svg viewBox="0 0 16 16">
                          <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                      <div>{i.titolo}</div>
                    </div>
                  )
                })}
              </div>
              <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer" style={{color:"white"}}>By Wikipedia</a></small>
              <br />
              <br />
            </div>
          </div>:info&&result&&result.filter(i=>i.tags.name===info.nome).length>0&&<div className='start-content-info-content' id='resizableDiv-info-content' style={result.filter(i=>i.tags.name===info.nome)[0].imageUrl?{backgroundImage:"url("+result.filter(i=>i.tags.name===info.nome)[0].imageUrl+")",backgroundPosition:"center",backgroundSize:"cover"}:{backgroundImage:"url("+loading+")",backgroundPosition:"center",backgroundSize:"contain"}}>
            <div className='start-content-info-content-content'>
              <div className='start-content-info-content-content-title'>
                <h2>{info.nome}</h2>
                <div className='start-content-info-content-content-title-rating'>
                  <svg viewBox="0 0 16 16">
                    <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z"/>
                  </svg>
                  <ViewVote id={info.id}/>
                  <select onChange={(e)=>utente?axios.put(serverURL+"setVote",{email:utente.email,password:utente.password,idUtente:utente._id,idWiki:info.id,vote:e.target.value},{headers:{'Content-Type':'multipart/form-data'}}):setAlertInfo({type:'error',text:'You must be logged in to vote'})}>
                    <option value="">Vote</option>
                    {[1,1.5,2,2.5,3,3.5,4,4.5,5].map((i,n)=>{
                      return(
                        <option key={n} value={i}>{i}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              {info.info[0]&&info.info[0].img&&info.info[0].img.length>0&&<div className='start-content-info-content-content-img'>
                {info.info[0].img.map((i,n)=>{
                  return(
                    <img key={n} src={i} alt={"img-"+info.nome} />
                  )
                })}
              </div>}
              <br />
              <div>
                <audio controls id='audio'></audio>
              </div>
              <div className='start-content-info-content-grid'>
                {result.filter(i=>i.tags.name===info.nome)[0].bounds?<div><small>Go to: </small><a href={"https://www.google.com/maps/place/"+result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlat+","+result.filter(i=>i.tags.name===info.nome)[0].bounds.maxlon} target='__blank'>directions</a></div>:<div><small>Go to: </small><a href={"https://www.google.com/maps/place/"+result.filter(i=>i.tags.name===info.nome)[0].lat+","+result.filter(i=>i.tags.name===info.nome)[0].lon} target='__blank'>directions</a></div>}
                {Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags).map((i,n)=>{
                  if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="website"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }else if(Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]==="phone"){
                    return(
                      <div key={n}><small>{Object.keys(result.filter(i=>i.tags.name===info.nome)[0].tags)[n]}</small>: <a href={"tel:"+result.filter(i=>i.tags.name===info.nome)[0].tags[i]} target='_blank' rel="noreferrer">{result.filter(i=>i.tags.name===info.nome)[0].tags[i]}</a></div>
                    )
                  }
                })}
              </div>
              <br />
              <big>Summary</big>
              <div className='start-content-info-content-summary'>
                <div>{info.info&&info.info.map(i=>i.riassunto+" ").join(' ')}</div>
              </div>
              <br />
              <big>Text</big>
              <div className='start-content-info-content-text' id='text' onClick={()=>openTextOSummary("text")}>
                <div>{info.info.map(i=>i.testo+" ").join(' ')}</div>
              </div>
              <br />
              <big>Audio</big>
              <div className='start-content-info-content-list'>
                <div className='start-content-info-content-list-item' onClick={()=>leggiTesto(info.info.map(i=>i.riassunto).join(' '),"Summary")}>
                  <div className='start-content-info-content-list-item-svg'>
                    <svg viewBox="0 0 16 16">
                      <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                    </svg>
                  </div>
                  <div>Summary</div>
                </div>
                {info&&info.info.map((i,n)=>{
                  return(
                    <div key={n} className='start-content-info-content-list-item' onClick={()=>leggiTesto(i.testo,i.titolo)}>
                      <div className='start-content-info-content-list-item-svg'>
                        <svg viewBox="0 0 16 16">
                          <path d="M8.5 2a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-1 0v-11a.5.5 0 0 1 .5-.5m-2 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m4 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-6 1.5A.5.5 0 0 1 5 6v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m8 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m-10 1A.5.5 0 0 1 3 7v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m12 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                      <div>{i.titolo}</div>
                    </div>
                  )
                })}
              </div>
              <small><a href={"https://it.wikipedia.org/wiki/"+info.nome} target="_blank" rel="noreferrer" style={{color:"white"}}>By Wikipedia</a></small>
              <br />
              <br />
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default Explore