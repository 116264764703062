import React,{useState} from 'react'
import './home.css'


const Home = ({utente}) => {
  const [c,setC]=useState(9)
  const [d,setD]=useState(9)
  const cities = [
    { 
      rank: 20, 
      city: "Amsterdam", 
      country: "Netherlands", 
      latitude: 52.3676, 
      longitude: 4.9041, 
      image: "https://images.pexels.com/photos/1790573/pexels-photo-1790573.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    { 
      rank: 19, 
      city: "Los Angeles", 
      country: "USA", 
      latitude: 34.0522, 
      longitude: -118.2437, 
      image: "https://images.pexels.com/photos/3166785/pexels-photo-3166785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    },
    { 
      rank: 18, 
      city: "Vienna", 
      country: "Austria", 
      latitude: 48.2082, 
      longitude: 16.3738, 
      image: "https://images.pexels.com/photos/2351425/pexels-photo-2351425.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    { 
      rank: 17, 
      city: "Berlin", 
      country: "Germany", 
      latitude: 52.5200, 
      longitude: 13.4050, 
      image: "https://images.pexels.com/photos/65567/pexels-photo-65567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 16,
      city: "Milan",
      country: "Italy",
      latitude: 45.4642,
      longitude: 9.1900,
      image: "https://images.pexels.com/photos/8243237/pexels-photo-8243237.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 15,
      city: "Prague",
      country: "Czech Republic",
      latitude: 50.0755,
      longitude: 14.4378,
      image: "https://images.pexels.com/photos/126292/pexels-photo-126292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 14,
      city: "Hong Kong",
      country: "Hong Kong",
      latitude: 22.3193,
      longitude: 114.1694,
      image: "https://images.pexels.com/photos/1757231/pexels-photo-1757231.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 13,
      city: "Sydney",
      country: "Australia",
      latitude: -33.8688,
      longitude: 151.2093,
      image: "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg?auto=compress&cs=tinysrgb&w=700",
      continent: "Oceania"
    },
    {
      rank: 12,
      city: "Moscow",
      country: "Russia",
      latitude: 55.7558,
      longitude: 37.6173,
      image: "https://images.pexels.com/photos/753339/pexels-photo-753339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 11,
      city: "Seoul",
      country: "South Korea",
      latitude: 37.5665,
      longitude: 126.9780,
      image: "https://images.pexels.com/photos/237211/pexels-photo-237211.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    { 
      rank: 10, 
      city: "Istanbul", 
      country: "Turkey", 
      latitude: 41.0082, 
      longitude: 28.9784, 
      image: "https://images.pexels.com/photos/3889704/pexels-photo-3889704.jpeg?auto=compress&cs=tinysrgb&w=600",
      continent: "Europe"
    },
    { 
      rank: 9, 
      city: "Barcelona", 
      country: "Spain", 
      latitude: 41.3851, 
      longitude: 2.1734, 
      image: "https://images.pexels.com/photos/1874675/pexels-photo-1874675.jpeg?auto=compress&cs=tinysrgb&w=700",
      continent: "Europe"
    },
    { 
      rank: 8, 
      city: "Singapore", 
      country: "Singapore", 
      latitude: 1.3521, 
      longitude: 103.8198, 
      image: "https://images.pexels.com/photos/326699/pexels-photo-326699.jpeg?auto=compress&cs=tinysrgb&w=600",
      continent: "Asia"
    },
    { 
      rank: 7, 
      city: "Bangkok", 
      country: "Thailand", 
      latitude: 13.7563, 
      longitude: 100.5018, 
      image: "https://images.pexels.com/photos/1031659/pexels-photo-1031659.jpeg?auto=compress&cs=tinysrgb&w=600",
      continent: "Asia"
    },
    {
      rank: 6,
      city: "Dubai",
      country: "Arab Emirates",
      latitude: 25.276987,
      longitude: 55.296249,
      image: "https://images.pexels.com/photos/1467300/pexels-photo-1467300.jpeg?auto=compress&cs=tinysrgb&w=600",
      continent: "Asia"
    },
    {
      rank: 5,
      city: "London",
      country: "UK",
      latitude: 51.507341,
      longitude: -0.127497,
      image: "https://images.pexels.com/photos/635608/pexels-photo-635608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 4,
      city: "Tokyo",
      country: "Japan",
      latitude: 35.680578,
      longitude: 139.768496,
      image: "https://images.pexels.com/photos/2506923/pexels-photo-2506923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 3,
      city: "Rome",
      country: "Italy",
      latitude: 41.896562,
      longitude: 12.482170,
      image: "https://images.pexels.com/photos/2422251/pexels-photo-2422251.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 2,
      city: "Paris",
      country: "France",
      latitude: 48.857450,
      longitude: 2.351338,
      image: "https://images.pexels.com/photos/3105066/pexels-photo-3105066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 1,
      city: "New York",
      country: "USA",
      latitude: 40.712376,
      longitude: -74.006040,
      image: "https://images.pexels.com/photos/1389339/pexels-photo-1389339.jpeg?auto=compress&cs=tinysrgb&w=800",
      continent: "North America"
    }
  ];
  const attractions = [
    {
      rank: 20,
      name: "Acropolis of Athens",
      city: "Athens",
      country: "Greece",
      latitude: 37.9715,
      longitude: 23.7257,
      image: "https://images.pexels.com/photos/15186555/pexels-photo-15186555/free-photo-of-citta-persone-punto-di-riferimento-edificio.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 19,
      name: "Empire State Building",
      city: "New York",
      country: "USA",
      latitude: 40.748817,
      longitude: -73.985428,
      image: "https://images.pexels.com/photos/2404843/pexels-photo-2404843.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    },
    {
      rank: 18,
      name: "Sagrada Familia",
      city: "Barcelona",
      country: "Spain",
      latitude: 41.4036,
      longitude: 2.1744,
      image: "https://images.pexels.com/photos/819764/pexels-photo-819764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 17,
      name: "Christ the Redeemer",
      city: "Rio de Janeiro",
      country: "Brazil",
      latitude: -22.9519,
      longitude: -43.2105,
      image: "https://images.pexels.com/photos/2868240/pexels-photo-2868240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "South America"
    },
    {
      rank: 16,
      name: "Sydney Opera House",
      city: "Sydney",
      country: "Australia",
      latitude: -33.8568,
      longitude: 151.2153,
      image: "https://images.pexels.com/photos/1878293/pexels-photo-1878293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Oceania"
    },
    {
      rank: 15,
      name: "Louvre Museum",
      city: "Paris",
      country: "France",
      latitude: 48.8606,
      longitude: 2.3376,
      image: "https://images.pexels.com/photos/11783055/pexels-photo-11783055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 14,
      name: "Petra",
      city: "Ma'an",
      country: "Jordan",
      latitude: 30.3285,
      longitude: 35.4444,
      image: "https://images.pexels.com/photos/4388167/pexels-photo-4388167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 13,
      name: "Grand Canyon",
      city: "Arizona",
      country: "USA",
      latitude: 36.1070,
      longitude: -112.1130,
      image: "https://images.pexels.com/photos/2379864/pexels-photo-2379864.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    },
    {
      rank: 12,
      name: "Mont Saint-Michel",
      city: "Normandy",
      country: "France",
      latitude: 48.6361,
      longitude: -1.5115,
      image: "https://images.pexels.com/photos/4245816/pexels-photo-4245816.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 11,
      name: "St. Peter's Basilica",
      city: "Vatican City",
      country: "Vatican",
      latitude: 41.9022,
      longitude: 12.4539,
      image: "https://images.pexels.com/photos/6188084/pexels-photo-6188084.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 10,
      name: "Machu Picchu",
      city: "Cusco",
      country: "Peru",
      latitude: -13.1631,
      longitude: -72.5450,
      image: "https://images.pexels.com/photos/90597/pexels-photo-90597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "South America"
    },
    {
      rank: 9,
      name: "Taj Mahal",
      city: "Agra",
      country: "India",
      latitude: 27.1751,
      longitude: 78.0421,
      image: "https://images.pexels.com/photos/5458393/pexels-photo-5458393.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 8,
      name: "Golden Gate Bridge",
      city: "San Francisco",
      country: "USA",
      latitude: 37.8199,
      longitude: -122.4783,
      image: "https://images.pexels.com/photos/1006964/pexels-photo-1006964.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    },
    {
      rank: 7,
      name: "Colosseum",
      city: "Rome",
      country: "Italy",
      latitude: 41.8902,
      longitude: 12.4922,
      image: "https://images.pexels.com/photos/2225439/pexels-photo-2225439.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 6,
      name: "Statue of Liberty",
      city: "New York",
      country: "USA",
      latitude: 40.6892,
      longitude: -74.0445,
      image: "https://images.pexels.com/photos/887848/pexels-photo-887848.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    },
    {
      rank: 5,
      name: "Pyramids of Giza",
      city: "Giza",
      country: "Egypt",
      latitude: 29.9792,
      longitude: 31.1342,
      image: "https://images.pexels.com/photos/3772630/pexels-photo-3772630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Africa"
    },
    {
      rank: 4,
      name: "Forbidden City",
      city: "Beijing",
      country: "Cina",
      latitude: 39.9163,
      longitude: 116.3972,
      image: "https://images.pexels.com/photos/189833/pexels-photo-189833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 3,
      name: "Palace of Versailles",
      city: "Versailles",
      country: "France",
      latitude: 48.8049,
      longitude: 2.1204,
      image: "https://images.pexels.com/photos/13692202/pexels-photo-13692202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Europe"
    },
    {
      rank: 2,
      name: "Great Wall of China",
      city: "Beijing",
      country: "China",
      latitude: 40.4319,
      longitude: 116.5704,
      image: "https://images.pexels.com/photos/2304791/pexels-photo-2304791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "Asia"
    },
    {
      rank: 1,
      name: "Yellowstone National Park",
      city: "Wyoming",
      country: "USA",
      latitude: 44.4280,
      longitude: -110.5885,
      image: "https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      continent: "North America"
    }
  ];
  const [suggest,setSuggest]=useState()
  function suggestCities(inputText){
    setSuggest()
    fetch(`https://nominatim.openstreetmap.org/search?city=${inputText}&format=json`)
      .then(response => response.json())
      .then(data =>{
        setSuggest(data)
      })
      .catch(error => {
        setSuggest("<div className='error'>Errore durante il recupero dei suggerimenti</div>");
      });
  }
  const [filter, setFilter] = useState()
  const filters=[{id:'europe',title:'Europe'}, {id:'asia',title:'Asia'}, {id:'nort_america',title:'North America'}, {id:'south_america',title:'South America'}, {id:'africa',title:'Africa'}, {id:'oceania',title:'Oceania'}, {id:'antartica',title:'Antarctica'}]
  return (
    <div className='home'>
      <div className='home-titles'>
        <div className='home-titles-text'>
          <h1>Discover your Next Escape</h1>
          <small>All world on your hand</small>
          <br />
          <a href="/explore">Go on Map</a>
        </div>
      </div>
      <div className='home-content'>
        <div className='home-content-search'>
          <input type="text" placeholder="🌍Enter a city to visit..." onChange={(e)=>e.target.value!==""?suggestCities(e.target.value):setSuggest()} />
          {suggest&&<div className='home-content-search-suggest'>
            <big>Maybe you looking for...</big>
            <br />
            <br />
            <div className='home-content-search-suggest-content'>
              {suggest.map(s=>{
                return(
                  <div className='home-content-search-suggest-content-item' onClick={()=>window.location.href="/explore?lat="+s.lat+"&&lon="+s.lon}>
                    <div>{s.display_name}</div>
                    <div>
                      <svg viewBox="0 0 16 16">
                        <path d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
                        <path d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
                      </svg>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
        <div className='home-content-subtitle'>
          <h3>Filters:</h3>
        </div>
        <div className='home-content-filters'>
          {filters.map(f=>{
            return(
              <div className='home-content-filters-item' onClick={()=>f.title===filter?setFilter(null):setFilter(f.title)} style={f.title===filter?{background:'black',color:'white'}:null} >
                <p>{f.title}</p>
              </div>
            )
          })}
        </div>
        <div className='home-content-subtitle'>
          <h3>Top Destinations: 🔥</h3>
        </div>
        <div className='home-content-popular'>
          {cities.reverse().slice(0,c).filter(e=>filter?e.continent===filter:e).map(city=>{
            return(
              <a href={"/explore?lat="+city.latitude+"&&lon="+city.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+city.image+")"}}></div>
                <div className='home-content-popular-item-content'>
                    <div className='home-content-popular-item-content-info'>
                      <small>
                        {city.rank}
                        <svg viewBox="0 0 16 16">
                          <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                        </svg>
                      </small>
                      <big>{' '+city.country}</big>
                    </div>
                    <p>{city.city}</p>
                    <small style={{color:'#c9c9c9'}} >{city.continent}</small>
                  </div>
              </a>
            )
          })}
          {cities.reverse().slice(0,d).filter(e=>filter?e.continent===filter:e).length===0&&<p style={{margin:"0 auto"}}>No Results Found</p>}
          {cities.reverse().slice(0,d).filter(e=>filter?e.continent===filter:e).length>0&&c<cities.length&&<div className='home-content-popular-arrow'>
            <svg onClick={()=>setC(cities.length)} viewBox="0 0 16 16">
              <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
          </div>}
        </div>
        {/*<div id='home-content-separator1' className='home-content-separator'>
          <div className='home-content-separator-text'>
            <p>We Are in +150 Country</p>
          </div>
          <div className='home-content-separator-img'>
            <img src={separator1} alt="separator1" />
          </div>
        </div>*/}
        <div className='home-content-subtitle'>
          <h3>Top sites: 🔥</h3>
        </div>
        <div className='home-content-popular'>
          {attractions.reverse().slice(0,d).filter(e=>filter?e.continent===filter:e).map(attraction=>{
            return(
              <a href={"/explore?lat="+attraction.latitude+"&&lon="+attraction.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+attraction.image+")"}}></div>
                <div className='home-content-popular-item-content'>
                  <div className='home-content-popular-item-content-info'>
                    <small>
                      {attraction.rank}
                      <svg viewBox="0 0 16 16">
                        <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                        <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                      </svg>
                    </small>
                    <big>{' '+attraction.city}, {attraction.country}</big>
                  </div>
                  <p>{attraction.name}</p>
                  <small style={{color:'#c9c9c9'}} >{attraction.continent}</small>
                </div>
              </a>
            )
          })}
          {attractions.reverse().slice(0,d).filter(e=>filter?e.continent===filter:e).length===0&&<p style={{margin:"0 auto"}}>No Results Found</p>}
          {attractions.reverse().slice(0,d).filter(e=>filter?e.continent===filter:e).length>0&&d<attractions.length&&<div className='home-content-popular-arrow'>
            <svg onClick={()=>setD(attractions.length)} viewBox="0 0 16 16">
              <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
          </div>}
        </div>
        {/*<div id='home-content-separator2' className='home-content-separator'>
          <div className='home-content-separator-img'>
            <img src={separator2} alt="separator2" />
          </div>
          <div className='home-content-separator-text'>
            <p>Explore the World for Free: Our Mission</p>
          </div>
        </div>*/}
        <div className='home-content-subtitle'>
          <h3>Cronology: 🕗</h3>
          <svg viewBox="0 0 16 16" onClick={()=>{
            if(window.confirm("Do you want delete your cronology?")){
              localStorage.removeItem("cronology")
              window.location.reload()
            }
          }}>
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
        </div>
        {localStorage.getItem("cronology")?<div className='home-content-popular'>
          {localStorage.getItem("cronology")&&JSON.parse(localStorage.getItem("cronology")).reverse().map((item,key)=>{
            return(
              <a href={"/explore?lat="+item.lat+"&&lon="+item.lon}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+item.img+")"}}></div>
                <div className='home-content-popular-item-content'>
                    <div className='home-content-popular-item-content-info'>
                      <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                        <svg viewBox="0 0 16 16">
                          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                        <big>{' '+new Date(item.data).getDate()}/{new Date(item.data).getMonth()}/{new Date(item.data).getFullYear()} {new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</big>
                      </div>
                      {utente&&utente.cronology&&utente.cronology.find(e=>item.nome===e.nome)&&<svg style={{fill:"#50C878"}} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                      </svg>}
                    </div>
                    <p>{item.nome}</p>
                    <small style={{color:'#c9c9c9'}} >Lat: {item.lat}, Lon: {item.lon}</small>
                  </div>
              </a>
            )
          })}
        </div>:<div className='home-content-popular' style={{backgroundImage:"url(https://img.freepik.com/free-vector/clock-increasing-chart-workflow-productivity-increase-work-performance-optimization-efficiency-indicator-rising-effectiveness-metrics-vector-isolated-concept-metaphor-illustration_335657-2718.jpg?t=st=1736507647~exp=1736511247~hmac=79a5040d403c7afc0aadc0a4a4b283a3012a2d4703d527f7d7174b124cd1ade0&w=1740)"}}></div>}
        <br />
      </div>
    </div>
  )
}

export default Home