import React from 'react'
import "./intro.css"

const Intro = () => {
  return (
    <div className='intro' style={{height:window.innerHeight}}>
      <div className='intro-container'>
        <div className='intro-container-text'>
            <h1>Let's Make <div style={{fontWeight:"900"}}>Your Digital Trip</div></h1>
            <a href='/home'>Get Started!</a>
        </div>
      </div>
    </div>
  )
}

export default Intro