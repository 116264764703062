import React, { useEffect } from 'react'
import "./navbar.css"

const Navbar = ({utente}) => {
  useEffect(()=>{
    if(window.location.pathname.includes("/explore")){
      document.getElementById("explore").style.fill="var(--black)"
      document.getElementById("explore").style.color="var(--black)"
    }else if(window.location.pathname.includes("/profile")||window.location.pathname.includes("/login")){
      document.getElementById("user").style.fill="var(--black)"
      document.getElementById("user").style.color="var(--black)"
    }else if(window.location.pathname.includes("/contacts")||window.location.pathname.includes("/install")||window.location.pathname.includes("/add_home_page")){
      document.getElementById("setting").style.fill="var(--black)"
      document.getElementById("setting").style.color="var(--black)"
    }else{
      document.getElementById("home").style.fill="var(--black)"
      document.getElementById("home").style.color="var(--black)"
    }
  },[])
  return (
    <nav className='navbar' id='navbar'>
      <div className='navbar-menu'>
        <a id='home' href='/home' className='navbar-item' title='Home'>
          <svg viewBox="0 0 16 16">
            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>
          </svg>
          <small>Home</small>
        </a>
        <a id='explore' href="/explore" className='navbar-item' title='Explore'>
          <svg viewBox="0 0 16 16">
          <path d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"/>
          </svg>
          <small>Explore</small>
        </a>
        <a id='user' className='navbar-item' href="/profile" title='Profile'>
          {!utente?<svg viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
          </svg>:<div id='user' className='navbar-item-me' title='Me'>
            <svg id='user' viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            </svg>
            <big>{utente.nome.charAt(0).toUpperCase()}</big>
          </div>}
          <small>Profile</small>
        </a>
        <div id='setting' className='navbar-item' title='Settings' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(0%)"}>
          <svg  viewBox="0 0 16 16">
            <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z"/>
          </svg>
          <small>Settings</small>
        </div>
      </div>
      <div className='navbar-list' id='navbar-list'>
        <div className='navbar-list-exit' onClick={()=>document.getElementById("navbar-list").style.transform="translateX(-101%)"}>
          <svg viewBox="0 0 16 16" style={{fill:'var(--red)'}}>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
          </svg>
        </div>
        <div className='navbar-list-access' onClick={()=>window.location.href="/"}>
          <a href="/login" style={{color:"var(--black)"}}>Login</a>
          <a href="/login?signup=true" style={{color:"white",background:"var(--black)"}}>Register</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/home'>Home</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/explore'>Explore</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/add_home_page'>Add to Home Page</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/contacts'>Contacts</a>
        </div>
        <div className='navbar-list-item'>
          <a href='/profile'>My Profile</a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar