import React, { useState,useEffect } from 'react'
import "./login.css"
import axios from 'axios'
import img from "../../logo.jpg"
import { Alert, Button} from "@material-tailwind/react";


const Login = ({serverURL}) => {
    document.title="Login | Journey"

    const [alertInfo, setAlertInfo] = useState()
    function AlertOk({text}) {
        const [open, setOpen] = useState(true);
        return (
          <Alert
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
              </svg>
            }
            open={open}
            className="alertOk"
            action={
              <Button
                variant="text"
                color="white"
                size="sm"
                className="!absolute top-3 right-3"
                onClick={() => {
                  setOpen(false)
                  setAlertInfo(null)
                }}
              >
                Close
              </Button>
            }
          >{text}</Alert>
        );
    }
    function AlertError({text}) {
        const [open, setOpen] = useState(true);
        return (
          <Alert
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-6 w-6"
              >
                <path
                  fillRule="evenodd"
                  d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                  clipRule="evenodd"
                />
              </svg>
            }
            open={open}
            className="alertError"
            action={
              <Button
                variant="text"
                color="white"
                size="sm"
                className="!absolute top-3 right-3"
                onClick={() => {
                  setOpen(false)
                  setAlertInfo(null)
                }}
              >Close</Button>
            }
          >{text}</Alert>
        );
    }

    const [caricamento,setCaricamento]=useState(false)
    const replaceSpaces=()=>{
        document.getElementById("nome").value=document.getElementById("nome").value.replace(/ /g, "/")
    }
    const signup=()=>{
        setCaricamento(true)
        axios.put(serverURL+"signup",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
            nome:document.getElementById("nome").value.replace("/"," "),
            professione:document.getElementById("professione").value,
            nascita:document.getElementById("nascita").value,
            data:new Date()
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                let date=new Date()
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
                const expires=date.toUTCString()
                document.cookie="email="+e.data.email+"; expires="+expires
                document.cookie="password="+e.data.password+"; expires="+expires
                setAlertInfo({type:'ok',text:"Registration Successful"})
                setTimeout(()=>window.location.href="/explore",2000)
            }else{
                setAlertInfo({type:'error',text:"Register Error: "+e.data})
            }
          }).catch((e)=>{
            setCaricamento(false)
            setAlertInfo({type:'error',text:"Register Error: "+e.data})
        })
    }
    const login=()=>{
        setCaricamento(true)
        axios.put(serverURL+"login",{
            email:document.getElementById("email").value,
            password:document.getElementById("password").value,
        },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
            setCaricamento(false)
            if(e.status===200){
                let date=new Date()
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
                const expires=date.toUTCString()
                document.cookie="email="+e.data.email+"; expires="+expires
                document.cookie="password="+e.data.password+"; expires="+expires
                setAlertInfo({type:'ok',text:"Login Successful"})
                setTimeout(()=>window.location.href="/explore",2000)
            }else{
              setAlertInfo({type:'error',text:"Login Error: "+e.data})
            }
        }).catch((e)=>{
            setCaricamento(false)
            setAlertInfo({type:'error',text:"Login Error: "+e})
        })
    }
    useEffect(()=>{
        const timer=setTimeout(()=>{
            if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
                window.location.href="/profile"
            }
        },1000);
        return ()=>clearTimeout(timer);
    },[]);
  return (
    <div className='login'>
        {alertInfo&&(alertInfo.type==='ok'?<AlertOk text={alertInfo.text}/>:<AlertError text={alertInfo.text}/>)}
        {caricamento&&<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p class="fade-text">Loading data from servers...</p><p class="fade-text">Translation of content into the selected language...</p><p class="fade-text">Preparing your personalized user experience...</p><p class="fade-text">Please wait thanks...</p></div></div></div>}
        <div className='login-content'>
            {window.location.search.substring(1).split("=")[1]==="true"&&window.location.search.substring(1).split("=")[0]==="signup"?<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                signup()
            }}>
                <div className='login-content-title'>
                    <img style={{borderRadius:"50%"}} src={img} alt="logo" />
                    <h1>Login / Signup</h1>
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="text" id='nome' onInput={()=>replaceSpaces()} placeholder='Name/Surname...' required/>
                </div>
                <div className='login-content-form-item'>
                    <select id="professione" required>
                        <option value="Healthcare and Assistance">Healthcare and Assistance</option>
                        <option value="Legal">Legal</option>
                        <option value="Education and Instruction">Education and Instruction</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Engineering and Architecture">Engineering and Architecture</option>
                        <option value="Commerce and Finance">Commerce and Finance</option>
                        <option value="Art and Design">Art and Design</option>
                        <option value="Science and Research">Science and Research</option>
                        <option value="Production and Manufacturing">Production and Manufacturing</option>
                        <option value="Marketing and Comunication">Marketing and Comunication</option>
                        <option value="Student">Student</option>
                    </select>
                </div>
                <div className='login-content-form-item'>
                    <input type="date" id='nascita' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname}>Login</div>
                </div>
            </form>:<form className='login-content-form' onSubmit={(e)=>{
                e.preventDefault()
                login()
            }}>
                <div className='login-content-title'>
                    <img style={{borderRadius:"50%"}} src={img} alt="logo" />
                    <h1>Login / Signup</h1>
                </div>
                <br />
                <br />
                <div className='login-content-form-item'>
                    <input type="email" id='email' placeholder='Email...' required/>
                </div>
                <div className='login-content-form-item'>
                    <input type="password" id='password' placeholder='Password...' required/>
                </div>
                <br />
                <div className='login-content-form-item'>
                    <button>Send</button>
                </div>
                <br />
                <div className='login-content-form-small'>
                    <div onClick={()=>window.location.href=window.location.pathname+"?signup=true"}>Don't You Have an Account? Signup</div>
                </div>
            </form>}
        </div>
        <br />
        <br />
    </div>
  )
}

export default Login