import React, { useEffect, useState } from "react";
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import axios from "axios"
import Intro from "./components/intro/Intro"
import Home from "./components/home/Home"
import Explore from "./components/explore/Explore";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Contacts from "./components/contacts/Contacts";
import Login from "./components/login/Login";
import Utente from "./components/utente/Utente";
import Footer from "./components/footer/Footer";
import Install from "./components/installation/Install";
import PageError from "./components/pageError/PageError";
import { Alert, Button} from "@material-tailwind/react";

function App(){

  const [alertInfo, setAlertInfo] = useState()
  function AlertOk({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
          </svg>
        }
        open={open}
        className="alertOk"
        action={
          <Button
            variant="text"
            color="white"
            size="sm"
            className="!absolute top-3 right-3"
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
          >
            Close
          </Button>
        }
      >{text}</Alert>
    );
  }
  function AlertError({text}) {
    const [open, setOpen] = useState(true);
    return (
      <Alert
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path
              fillRule="evenodd"
              d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
              clipRule="evenodd"
            />
          </svg>
        }
        open={open}
        className="alertError"
        action={
          <Button
            variant="text"
            color="white"
            size="sm"
            className="!absolute top-3 right-3"
            onClick={() => {
              setOpen(false)
              setAlertInfo(null)
            }}
          >Close</Button>
        }
      >{text}</Alert>
    );
  }

  const [utente,setUtente]=useState()
  const serverURL="https://cultura-server.onrender.com/"
  //const serverURL="http://localhost:3001/"
  const stayLogin=()=>{
    if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
      axios.put(serverURL+"login",{
        email:document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1],
        password:document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1],
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          setUtente(e.data)
          let date=new Date()
          date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
          const expires=date.toUTCString()
          document.cookie="email="+document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1]+"; expires="+expires
          document.cookie="password="+document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1]+"; expires="+expires
        }else{
          setAlertInfo({type:'error',text:'Error: You are not logged in'})
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          setUtente(203)
        }
      })
    }
  }
  const handleError = (error) => {
    setAlertInfo({type:'error',text:'Error: '+JSON.stringify(error)})
  };
  useEffect(()=>{
    stayLogin()
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
  },[])
  
  return (
    <BrowserRouter>
      {!window.location.pathname.endsWith("/")&&<Navbar utente={utente}/>}
      {alertInfo&&(alertInfo.type==='ok'?<AlertOk text={alertInfo.text}/>:<AlertError text={alertInfo.text}/>)}
      <Routes>
        <Route path='/' element={<Intro/>}/>
        <Route path='/home' element={<Home serverURL={serverURL} utente={utente}/>}/>
        <Route path='/who' element={<About/>}/>
        <Route path='/contacts' element={<Contacts serverURL={serverURL}/>}/>
        <Route path='/explore' element={<Explore serverURL={serverURL} utente={utente}/>}/>
        <Route path='/login' element={<Login serverURL={serverURL}/>}/>
        <Route path='/profile' element={<Utente serverURL={serverURL} utente={utente}/>}/>
        <Route path='/add_home_page' element={<Install serverURL={serverURL} utente={utente}/>}/>
        <Route path='/*' element={<PageError />}/>
      </Routes>
      {!window.location.pathname.endsWith("/")&&!window.location.pathname.includes("explore")&&<Footer/>}
    </BrowserRouter>
  );
}

export default App;
